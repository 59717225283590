import request from "@/utils/request";

// 购物车支付
export function paypal(query) {
  return request({
    url: '/shopping/payPal',
    headers: {
      isToken: true
    },
    method: 'post',
	data: query
  })
}